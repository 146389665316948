import React from 'react'
import { Grid, Slider, Typography } from '@material-ui/core'
function axisToSliderValue (axisValue) {
  return (axisValue * 100) / 3440
}
function sizeToSliderValue (sliderValue) {
  return (sliderValue * 100) / 5500
}

function ImageElementEditor ({ elem, onElementChange }) {
  function handleXAxisChange (event, value) {
    const newX = (value * 3440) / 100
    onElementChange({ ...elem, coordinates: { ...elem.coordinates, x: newX } })
  }
  function handleYAxisChange (event, value) {
    const newY = (value * 3440) / 100
    onElementChange({ ...elem, coordinates: { ...elem.coordinates, y: newY } })
  }
  function handleSizeChange (event, value) {
    const newSize = (value * 5500 / 100)
    onElementChange({ ...elem, style: { ...elem.style, width: newSize.toString() } })
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography gutterBottom>
          X-Axis
        </Typography>
        <Slider value={axisToSliderValue(elem.coordinates.x)} onChange={handleXAxisChange} valueLabelDisplay='auto' />
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom>
          Y-Axis
        </Typography>
        <Slider value={axisToSliderValue(elem.coordinates.y)} onChange={handleYAxisChange} valueLabelDisplay='auto' />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>
          Size
        </Typography>
        <Slider onChange={handleSizeChange} value={sizeToSliderValue(elem.style.width)} valueLabelDisplay='auto' />
      </Grid>
    </Grid>
  )
}

export default ImageElementEditor
