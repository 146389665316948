import React from 'react'
import
{
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  DialogActions,
  Button
}
  from '@material-ui/core'

export default function ShoppingCart({open, setCartIsOpen, removeItemFromCart, cartState}){
  return (
  <Dialog open={open} maxWidth={"md"} fullWidth onClose={() => setCartIsOpen(false)}>
      {cartState?.checkout?.lineItems?.length === 0 ? (
        <DialogContentText>
          Your Cart Is Empty.
        </DialogContentText>
      ):(
       <>
       <DialogTitle>
         Shopping Cart
       </DialogTitle>
       <DialogContent>
         <TableContainer>
           <Table aria-label="simple table">
             <TableHead>
               <TableRow>
                 <TableCell>Product</TableCell>
                 <TableCell align="right">Quantity</TableCell>
                 <TableCell align="right">Price</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {cartState?.checkout?.lineItems?.map(lineItem => {
                 return(
                   <TableRow key={lineItem.id}>
                     <TableCell component="th" scope="row">
                       <Typography variant={'h6'}>
                         {lineItem.title}
                       </Typography>
                       <Typography variant={'caption'}>
                         {lineItem.variant.title}
                       </Typography>
                       <Button onClick={() => {
                         removeItemFromCart(lineItem.id)
                       }}>Remove</Button>
                     </TableCell>
                     <TableCell align="right">{lineItem.quantity}</TableCell>
                     <TableCell align="right">{`$${lineItem.variant.price}`}</TableCell>
                   </TableRow>
                 )
               })}
             </TableBody>
           </Table>
         </TableContainer>
       </DialogContent>
         <DialogActions>
           <Typography>{`Subtotal: $${cartState.checkout?.subtotalPrice}`}</Typography>
           <Button variant={"contained"} color="secondary" onClick={() => window.location.href = cartState.checkout.webUrl }>Checkout</Button>
         </DialogActions>
       </>
      )}

  </Dialog>
  )
}