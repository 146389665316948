import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import { IconButton, Badge } from '@material-ui/core'
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons'
import ShoppingCart from '../ShoppingCart/ShoppingCart'
import ProductCard from '../ProductCard/ProductCard'

function Copyright () {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
      Inkee Tree
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontFamily: ['Bungee Shade'],
    color: '#ff6b6b'
  }
}))

const cards = [
  {
    key: 0,
    imagePath: 'map-image.jpg',
    heading: 'City Map',
    description: 'Create a personalized map with a route to a meaningful location',
    path: '/map-builder'
  }
  // not ready yet
  // {
  //   key: 1,
  //   imagePath: 'tiny-textposter.jpg',
  //   heading: 'Micro Text Print',
  //   description: 'A stunning print made up of a micro printed text of your choice.',
  //   path: '/poster'
  // }

]

export default function LandingPage ({ checkout, client }) {
  const classes = useStyles()

  return (
    <>

      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth='sm'>
            <Typography className={classes.title} component='h1' variant='h2' align='center' color='textPrimary' gutterBottom>
              Inkee Tree
            </Typography>
            <Typography variant='h5' align='center' color='textSecondary' paragraph>
              Personalized prints on the cheap.
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth='md'>
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((cardData) => (
              <ProductCard cardData={cardData} />
            ))}
          </Grid>
        </Container>
      </main>

    </>
  )
}
