import React from 'react'
import { Button, Grid } from '@material-ui/core'
import CustomTinyTextModal from '../CustomTinyTextModal/CustomTinyTextModal'

function renderTextPreview (string) {
  let stringPreview = ''
  let i
  for (i = 0; i <= 150 && string.length > i; i++) {
    stringPreview = stringPreview + string.charAt(i)
  }
  console.log(i)
  if (i === 151) {
    stringPreview = stringPreview + '...'
  }
  return stringPreview
}

function AddEditTinyText ({ tinyText, setTinyText }) {
  const [openTinyTextModal, setOpenTinyTextModal] = React.useState(false)
  return (
    <Grid container justify='center'>
      <CustomTinyTextModal tinyText={tinyText} setTinyText={setTinyText} open={openTinyTextModal} onClose={() => setOpenTinyTextModal(false)} />
      {tinyText ? (
        <>
          <Grid item xs={10}>
            <p>{renderTextPreview(tinyText)}</p>
          </Grid>
          <Grid item>
            <Button color='primary' variant='contained' onClick={() => setOpenTinyTextModal(true)}>Edit your tiny text</Button>
          </Grid>
        </>
      ) : (
        <Grid item>
          <Button color='primary' variant='contained' onClick={() => setOpenTinyTextModal(true)}>Add your tiny text</Button>
        </Grid>
      )}
    </Grid>
  )
}

export default AddEditTinyText
