import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, TextField, DialogContentText, DialogActions } from '@material-ui/core'
function CustomTinyTextModal ({ tinyText, open, onClose, setTinyText }) {
  const [inputValue, setInputValue] = React.useState('')
  React.useEffect(() => {
    setInputValue(tinyText)
  }, [tinyText])
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
       Add your Tiny Text.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will repeat to fill all necessary space.
        </DialogContentText>
        <TextField
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          variant='outlined'
          multiline
          value={inputValue}
          rows={5}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setTinyText(inputValue)
          onClose()
        }}
        >Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomTinyTextModal
