import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(-1),
    right: theme.spacing(-1)
  }
}))
function fontLinkToBase64 (link) {
  return new Promise((resolve, reject) => {
    axios.get(link).then(resp => {
      const contentType = resp.headers['content-type']
      const base64Data = 'data:' + contentType + ';charset=utf-8;base64,' + btoa(unescape(encodeURIComponent(resp.data)))
      const dataObj = {
        link: link,
        base64: base64Data
      }
      console.log(resp)
      resolve(dataObj)
    })
  }
  )
}

function getProcessedCSS (css) {
  return new Promise(function (resolve, reject) {
    const fontLinkRegex = new RegExp(/(https?:\/\/(fonts\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})\w/, 'ig')
    const matches = css.match(fontLinkRegex)

    // BUG: the last url always throws errors when converted to base64 for some reason.
    matches.splice(-1, 1)

    const promises = []

    matches.forEach(function (link) {
      promises.push(fontLinkToBase64(link))
    })

    Promise.all(promises).then(function (fontData) {
      fontData.forEach(function (data) {
        css = css.replace(data.link, data.base64)
      })
      resolve(css)
    })
  })
}
// todo make this seperate component file
function TextSvg ({ edit, selectedElementId, elem, setSelectedElementId, svgBoxDimensions }) {
  const [processedFontCSS, setProcessedFontCSS] = React.useState('')
  // todo add the element dimentions and edit box to SvgEditElement
  const [textDimentions, setTextDimentions] = React.useState()
  const textRef = React.useRef(null)
  React.useEffect(() => {
    if (textRef) {
      setTextDimentions(textRef.current.getBoundingClientRect())
    }
  }, [elem, textRef])
  React.useEffect(() => {
    axios.get(`https://fonts.googleapis.com/css?family=${encodeURI(elem.style.fontFamilyName)}&display=swap`).then(data => {
      getProcessedCSS(data.data).then(css => {
        console.log(css)
        setProcessedFontCSS(css)
      })
    })
  }, [elem.style.fontFamilyName])
  return (
    <g>

      <g>
        <style type='text/css'>
          {processedFontCSS}
          {`#${elem.id}{ 
          font-family: ${elem.style.fontFamilyName}
          line-height: 70%
          }`}
        </style>
        <defs />
        <text
          ref={textRef}
          onClick={() => setSelectedElementId(elem.id)}
          x={elem.coordinates.x}
          y={elem.coordinates.y}
          id={elem.id}
          fontFamily={elem.style.fontFamilyName}
          fill='black'
          style={{
            fontSize: elem.style.fontSize,
            cursor: 'pointer'
          }}
        >
          {elem.value}
        </text>
        {textDimentions && selectedElementId === elem.id && (
          <rect
            x={elem.coordinates.x}
            y={elem.coordinates.y - ((textDimentions.height * 1440) / svgBoxDimensions.height) + (((textDimentions.height * 1440) / svgBoxDimensions.height) * 0.22)}
            width={(textDimentions.width * 1440) / svgBoxDimensions.width}
            height={(textDimentions.height * 1440) / svgBoxDimensions.height}
            style={{ fill: 'none', stroke: 'blue' }}
            strokeWidth='.08%'
            strokeDasharray='13,13'
          />
        )}
      </g>
    </g>
  )
}
function ImageSvg ({ edit, selectedElementId, elem, setSelectedElementId, svgBoxDimensions }) {
  const [imageDimentions, setImageDimentions] = React.useState()
  const textRef = React.useRef(null)
  React.useEffect(() => {
    if (textRef) {
      setImageDimentions(textRef.current.getBoundingClientRect())
    }
  }, [elem, textRef])
  return (
    <svg width={elem.style.width} height='auto' x={elem.coordinates.x} y={elem.coordinates.y - ((elem.style.width * 4) / 20)} onClick={() => setSelectedElementId(elem.id)} viewBox={elem.viewBox}>
      <svg ref={textRef} dangerouslySetInnerHTML={{ __html: elem.svgString }} />
      {imageDimentions && selectedElementId === elem.id && (
        <rect
          width={elem.viewBox.split(' ')[2] - 20}
          height={elem.viewBox.split(' ')[3] - 20}
          // height={(imageDimentions.height * 3440) / svgBoxDimensions.height}
          style={{ fill: 'none', stroke: 'blue' }}
          strokeWidth='.8%'
          strokeDasharray='65,65'
        />
      )}
    </svg>
  )
}
function SvgEditElement ({ edit, elem, handleClick, onClick, selectedElementId, setSelectedElementId, svgBoxDimensions }) {
  switch (elem.type) {
    case 'TEXT':
      return <TextSvg edit={edit} svgBoxDimensions={svgBoxDimensions} selectedElementId={selectedElementId} handleClick={handleClick} onClick={onClick} setSelectedElementId={setSelectedElementId} elem={elem} />
    case 'IMAGE':
      return <ImageSvg edit={edit} svgBoxDimensions={svgBoxDimensions} selectedElementId={selectedElementId} handleClick={handleClick} onClick={onClick} setSelectedElementId={setSelectedElementId} elem={elem} />
    default:
      return null
  }
}
// function generateSVGBackgroundText (string) {
//   let svgText = ''
//   svgText = svgText + '<rect width="3440" height="3440" fill="white" x="0" y="0"/>'
//   let stringIndex = 0
//   svgText = svgText + "<text x='0' y='0' dy='0'>"
//   for (let i = 0; i < 610; i++) {
//     svgText = svgText + "<tspan x='0' dy='8'>"
//     for (let j = 0; j < 1100; j++) {
//       if (stringIndex > string.length) {
//         stringIndex = 0
//       }
//       svgText = svgText + string.charAt(stringIndex)
//       stringIndex++
//     }
//     svgText = svgText + '</tspan>'
//   }
//   svgText = svgText + '</text>'
//   return svgText
// }
function SVGRenderer ({ tinyText, onAddButtonClick, editable, selectedElementId, setSelectedElementId, selectSvgElement, svgData, setSvgRef = () => {} }) {
  const [svgBoxDimensions, setSvgBoxDimensions] = React.useState()
  // const [backgroundText, setBackgroundText] = React.useState('')
  const svgBoxRef = React.useRef(null)

  // React.useEffect(() => {
  //   setBackgroundText(generateSVGBackgroundText(tinyText))
  // }, [tinyText])

  React.useEffect(() => {
    if (svgBoxRef.current) {
      setSvgBoxDimensions(svgBoxRef.current.getBoundingClientRect())
    }
  }, [svgBoxRef])

  function handleRefChange (ref) {
    if (ref) {
      setSvgRef(ref)
    }
  }
  const classes = useStyles()
  return (
    <div style={{ position: 'relative' }}>
      {editable ? (
        <>
          <svg viewBox='0 0 1440 1440' ref={svgBoxRef}>
            <svg height={3440} width={3440} x={-860} y={-860}>
              <rect id='background-to-switch' x='0' y='0' width={3440} height={3440} fill='white' />
              <g style={{ userSelect: 'none' }}>
                {svgData.elements.map(elem => (
                  <SvgEditElement edit svgBoxDimensions={svgBoxDimensions} selectedElementId={selectedElementId} setSelectedElementId={setSelectedElementId} key={elem.id} elem={elem} />
                ))}
              </g>
            </svg>
          </svg>
          <Fab onClick={onAddButtonClick} size='small' color='primary' className={classes.fab}>
            <AddIcon />
          </Fab>
        </>
      ) : (
        <svg viewBox='0 0 1440 1440'>
          <svg ref={ref => handleRefChange(ref)} viewBox='0 0 1440 1440' height={1440} width={1440}>
            <svg height={3440} width={3440} x={-860} y={-860}>
              <defs>
                <style>
                  {`text {
                  font-size: 4px;
                  line-height: 75%
                  }`}
                </style>
                <mask id='knockout-text'>
                  <rect width={3440} height={3440} fill='#fff' x='0' y='0' />
                  {/* // map out elements here */}
                  {svgData.elements.map(elem => (
                    <SvgEditElement edit={false} selectedElementId='' setSelectedElementId={() => {}} key={elem.id} elem={elem} />
                  ))}
                </mask>
              </defs>
              <g style={{ userSelect: 'none' }}>
                <rect id='background-to-switch' x='0' y='0' width={3440} height={3440} fill='black' />
                {/* <g dangerouslySetInnerHTML={{ __html: backgroundText }} /> */}
                {/* insert example background text here */}
                <rect
                  className='knockout-text-bg'
                  width={3440}
                  height={3440}
                  fill='#ffffff'
                  x='0'
                  y='0'
                  fillOpacity='1'
                  mask='url(#knockout-text)'
                />
              </g>
            </svg>
          </svg>
        </svg>
      )}

    </div>
  )
}

export default SVGRenderer
