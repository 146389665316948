import React from 'react'
import Magnifier from 'react-magnifier'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
  spinner: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}))
function SvgPreview ({ src }) {
  const classes = useStyles()
  return (
    <>
      {src ? <Magnifier src={`data:image/png;base64,${src}`} zoomFactor={3} width='100%' /> : (
        <div style={{ width: '100%', paddingTop: '100%', backgroundColor: 'white', position: 'relative' }}>
          <CircularProgress className={classes.spinner} />
        </div>
      )}

    </>
  )
}

export default SvgPreview
