import React from 'react'
import { TextField, Slider, Typography, Grid } from '@material-ui/core'

import FontPicker from 'font-picker-react'
// const FontPicker = React.lazy(() => import('font-picker-react'))
function axisToSliderValue (axisValue) {
  return (axisValue * 100) / 3440
}
function sizeToSliderValue (sliderValue) {
  return (sliderValue * 100) / 1500
}
function TextElementEditor ({ elem, onElementChange }) {
  function handleXAxisChange (event, value) {
    const newX = (value * 3440) / 100
    onElementChange({ ...elem, coordinates: { ...elem.coordinates, x: newX } })
  }
  function handleYAxisChange (event, value) {
    const newY = (value * 3440) / 100
    onElementChange({ ...elem, coordinates: { ...elem.coordinates, y: newY } })
  }
  function handleSizeChange (event, value) {
    const newSize = (value * 1500 / 100)
    onElementChange({ ...elem, style: { ...elem.style, fontSize: newSize } })
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          value={elem.value}
          onChange={(e) => onElementChange({ ...elem, value: e.target.value })}
          label='Value'
        />
      </Grid>
      <Grid item xs={6}>
        <FontPicker
          apiKey='AIzaSyA_2FvyobrnFgrwocjLxhU5fqOkRX0K3Xk'
          sort='popularity'
          limit='400'
          activeFontFamily={elem.style.fontFamilyName}
          onChange={font => onElementChange({ ...elem, style: { ...elem.style, fontFamilyName: font.family } })}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom>
        X-Axis
        </Typography>
        <Slider value={axisToSliderValue(elem.coordinates.x)} onChange={handleXAxisChange} valueLabelDisplay='auto' />
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom>
        Y-Axis
        </Typography>
        <Slider value={axisToSliderValue(elem.coordinates.y)} onChange={handleYAxisChange} valueLabelDisplay='auto' />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>
          Size
        </Typography>
        <Slider onChange={handleSizeChange} value={sizeToSliderValue(elem.style.fontSize)} valueLabelDisplay='auto' />
      </Grid>
    </Grid>
  )
}

export default TextElementEditor
