import React from 'react'
import
{
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  DialogActions,
  Button,
  CircularProgress
}
  from '@material-ui/core'

export default function AddToCartConfirmation ({ cartState, open, setConfirmAddIsOpen, setCartIsOpen }) {
  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      {cartState.loading ? (
        <DialogContent style={{ display: 'flex' }}>
          <CircularProgress style={{ margin: 'auto' }} color='secondary' />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
          Added To Cart!
          </DialogTitle>
          <DialogActions>
            <Button
              variant='outlined' color='secondary' onClick={() => {
                setConfirmAddIsOpen(false)
                setCartIsOpen(true)
              }}
            >Go To Cart
            </Button>
            <Button color='secondary' onClick={() => window.location.href = '/'}>Continue Shopping</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
