import React from 'react'
import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core'

function PreviewProgressDialog () {
  return (
    <Dialog fullWidth maxWidth='md' open>
      <DialogTitle>Generating Preview</DialogTitle>
      <DialogContent>
        <LinearProgress variant='indeterminate' />
      </DialogContent>
    </Dialog>
  )
}

export default PreviewProgressDialog
