import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core'
import potrace from 'potrace'

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

function AddElementModal ({ open, onClose, onAddTextElement, onAddImage }) {
  async function handleImage (file) {
    if (file) {
      const preview = await getBase64(file)
      potrace.trace(preview, (err, svg) => {
        debugger
        onAddImage(svg, svg.match(/viewBox="(.*)" ver/)[1])
      })
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Add element
      </DialogTitle>
      <DialogContent>
        <Button onClick={() => onAddTextElement()} variant='contained' color='primary'>Text</Button>
        <input
          accept='image/*'
          style={{ display: 'none' }}
          id='contained-button-file'
          multiple
          type='file'
          onChange={(event) => handleImage(event.target.files[0])}
        />
        <label htmlFor='contained-button-file'>
          <Button variant='contained' color='primary' component='span'>
            Upload Image
          </Button>
        </label>
      </DialogContent>
    </Dialog>

  )
}

export default AddElementModal
