export default {
  apiKey: 'AIzaSyB5HOn6do7I2GGJvAJucCFDP8htob_p7xE',
  authDomain: 'wordywords-3514b.firebaseapp.com',
  databaseURL: 'https://wordywords-3514b.firebaseio.com',
  projectId: 'wordywords-3514b',
  storageBucket: 'wordywords-3514b.appspot.com',
  messagingSenderId: '445211454341',
  appId: '1:445211454341:web:9c117ae68794b18bee32ec',
  measurementId: 'G-8RW0J3GJ8L'
}
