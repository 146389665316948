import React from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Paper,
  TextField,
  Button,
  Grid,
  Toolbar,
  Typography,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'

import PosterSvgRenderer from '../PosterSvgRenderer/PosterSvgRenderer'
import FontPicker from 'font-picker-react'
import axios from 'axios'
import ProgressDialog from '../ProgressDialog/ProgressDialog'
import createUUID from '../createUUID'
import ShoppingCart from '../ShoppingCart/ShoppingCart'

const PRODUCT_DATA = {
  SIZES: ['24" x 36"', '20" x 30"', '12" x 18"'],
  TYPES: ['Premium Matte Poster (175 gsm fine art paper)', 'Download Only (High Resolution PNG)']
}

const BUY_VARIANTS = [
  // download 24 x 36
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzMxOTIxNjU5NTExMg==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[0],
    price: '$10'
  },
  // download 20x30
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzMxOTIxNjY2MDY0OA==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[1],
    price: '$10'
  },
  // download 12x18
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzMxOTIxNjcyNjE4NA==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[2],
    price: '$10'
  },
  // print 24x36
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzMxOTIxNjU2MjM0NA==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[0],
    price: '$30'
  },
  // print 20x30
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzMxOTIxNjYyNzg4MA==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[1],
    price: '$24'
  },
  // print 12x18
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzMxOTIxNjY5MzQxNg==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[2],
    price: '$17'
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  textField: { },
  grid: {
    padding: theme.spacing(2)
  },
  stepper: {
    // margin: theme.spacing(2)
    backgroundColor: '#dadde3'
  },
  media: {
    height: 140
  },
  card: {
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    // Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    fontFamily: ['Helvetica', '"Helvetica Neue"', 'Arial', '"Lucinda Grande"', 'sans-serif'],
    letterSpacing: '0.1em',
    fontSize: '1.33333em',
    fontWeight: '700',
    color: '#3a3a3a'
  },
  appBar: {
    backgroundColor: '#fff'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}))

// TODO VALIDATE AND ADD LENGTH LIMITS PLZ
function PosterBuilder ({addItemsToCart}) {
  const [letter, setLetter] = React.useState('H')
  // const [fetching, setFetching] = React.useState(false)
  // const [resolvedFetch, setResolvedFetch] = React.useState(false)
  // const [imageId, setImageId] = React.useState('')
  // const [svgRef, setSvgRef] = React.useState(null)
  const [subtitle, setSubtitle] = React.useState('Halpert')
  const [size, setSize] = React.useState(PRODUCT_DATA.SIZES[0])
  const [type, setType] = React.useState(PRODUCT_DATA.TYPES[0])
  const [buyVariant, setBuyVariant] = React.useState()
  const [secondSubtitle, setSecondSubtitle] = React.useState('est. 2009')
  const [tinyText, setTinyText] = React.useState('')
  const [fontFamily, setFontFamily] = React.useState('Lobster')
  // const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://tiny-text.herokuapp.com'
  const classes = useStyles()

  React.useEffect(() => {
    setBuyVariant(BUY_VARIANTS.find(variant => {
      return type === variant.type && variant.size === size
    }))
  }, [type, size])


  return (
    <div
      css={css`background-color: #fff; height: 100%; min-height: 100vh; display: flex; flex-direction
: column`} className={classes.root}
    >
      <div css={css`flex-grow: 1; padding-top: 20px`}>
        <Grid className={classes.grid} justify='center' direction='row-reverse' container spacing={2}>
          {/* <MainStepper activeStep={activeStep} /> */}
          <Grid item lg={5} xs={12}>
            <PosterSvgRenderer fontFamily={fontFamily} letter={letter} subtitle={subtitle} secondSubtitle={secondSubtitle} />
          </Grid>
          <Grid item lg={5} xs={10}>
            <Paper>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <TextField label='Letter' value={letter} onChange={e => setLetter(e.target.value)} inputProps={{ maxLength: '1' }} />
                </Grid>
                <Grid item>
                  <TextField label='Subtitle 1' value={subtitle} onChange={e => setSubtitle(e.target.value)} />
                </Grid>
                <Grid item>
                  <TextField label='Subtitle 2 (optional)' value={secondSubtitle} onChange={e => setSecondSubtitle(e.target.value)} />
                </Grid>
                <Grid item>
                  <TextField multiline rows={6} onChange={e => setTinyText(e.target.value)} />
                </Grid>
                <Grid item>
                  <FontPicker
                    apiKey='AIzaSyA_2FvyobrnFgrwocjLxhU5fqOkRX0K3Xk'
                    sort='popularity'
                    limit='400'
                    activeFontFamily={fontFamily}
                    onChange={(font) => { setFontFamily(font.family) }}
                  />
                </Grid>
                <Grid item>
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select value={type} onChange={(e) => setType(e.target.value)}>
                    {PRODUCT_DATA.TYPES.map(type => {
                      return <MenuItem value={type}>{type}</MenuItem>
                    })}
                  </Select>
                </Grid>
                <Grid item>
                  <InputLabel id="size-label">Size</InputLabel>
                  <Select value={size} onChange={(e) => setSize(e.target.value)}>
                    {PRODUCT_DATA.SIZES.map(size => {
                      return <MenuItem value={size}>{size}</MenuItem>
                    })}
                  </Select>
                </Grid>
                <Grid item>
                  <h3>{buyVariant?.price}</h3>
                </Grid>
                <Grid item>
                  <Button onClick={() => {
                    //todo show spinner while this loads
                    const lineItemsToAdd = [
                      {
                        variantId: buyVariant.id,
                        quantity: 1,
                        customAttributes: [
                          {key: '_image_id', value: createUUID()},
                          {key: 'letter', value: letter},
                          {key: 'title', value: subtitle},
                          {key: 'subtitle', value: secondSubtitle},
                          {key: 'font_family', value: fontFamily},
                          {key: '_tiny_text', value: tinyText},
                        ]
                      }
                    ]
                   addItemsToCart(lineItemsToAdd)
                  }} variant='contained'>Add to Cart</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      {/*<ShoppingCart checkout={checkout} confirmingFirst setCartIsOpen={setCartIsOpen} open={cartIsOpen}/>*/}
    </div>
  )
}

export default PosterBuilder
