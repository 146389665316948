import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '99.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  }
}))
export default function ProductCard ({ cardData }) {
  const classes = useStyles()
  return (
    <Grid item key={cardData.key} xs={12} sm={6} md={4}>
      <Card className={classes.card} raised>
        <CardMedia
          className={classes.cardMedia}
          image={cardData.imagePath}
          title='Image title'
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant='h5' component='h2'>
            {cardData.heading}
          </Typography>
          <Typography>
            {cardData.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button fullWidth variant='contained' href={cardData.path} size='large' color='secondary'>
            Design Now
          </Button>
        </CardActions>
      </Card>
    </Grid>)
}
