import React from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import queryString from 'query-string'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  title: {
    fontFamily: ['Bungee'],
    color: 'black'
  }
}))

export default function DownloadPage () {
  const [imageReady, setImageReady] = React.useState(true)
  const [countDownTime, setCountDownTime] = React.useState(70)
  const classes = useStyles()
  const image_id = queryString.parse(window.location.search).image_id
  const imageUrl = `https://storage.googleapis.com/felix_ink_rendered_images/${image_id}.png`

  React.useEffect(() => {
    if (countDownTime === 0) {
      window.location.reload()
    }
  }, [countDownTime])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCountDownTime(prev => {
        return prev - 1
      })
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  function handleLoadFailed () {
    setImageReady(false)
    // document.getElementById("download-page-title").innerHTML = "Image Ready Soon!"
    // //hide image
    // document.getElementById("image-preview").style.display = "none"
    // // unhide did no load div
    // document.getElementById("did-not-load-content").style.display = "block"
  }
  function handleLoad () {
    window.location.href = imageUrl
  }
  return (
    <>
      {imageReady ? (
        <img alt='download-placeholder' src={imageUrl} id='image-preview' onError={handleLoadFailed} onLoad={handleLoad} />
      ) : (
        <div className={classes.heroContent}>
          <Grid container spacing={2} direction='column' alignContent='center'>
            <Grid item>
              <Container maxWidth='md'>
                <Typography className={classes.title} component='h1' variant='h3' align='center' color='textPrimary' gutterBottom>
                Your Image File Will Be Ready Soon!
                </Typography>
                <Typography variant='h5' align='center' color='textSecondary' paragraph>
                Our servers are still building your image file. <br />
                You will find it here within one hour of purchase.
                </Typography>
                <Typography variant='h5' align='center' color='textSecondary' paragraph>
                 (We will also send you an email when it is ready.)
                </Typography>
                <Typography align='center'>
                  <CircularProgress color='secondary' />
                </Typography>
                <Typography component='h3' variant='h5' align='center' color='textSecondary'>
                Reloading Page In <span style={{ fontFamily: 'Bungee' }}>{countDownTime}</span> seconds
                </Typography>
              </Container>
            </Grid>
            <img style={{ maxWidth: '45%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt='waiting-gif' src='https://media.giphy.com/media/QPQ3xlJhqR1BXl89RG/giphy.gif' />
            {/* <Grid item alignContent='center' alignItems='center'> */}
            {/* </Grid> */}
          </Grid>
        </div>
      )}
    </>
  )
}
