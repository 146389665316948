import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Fab, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'


const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(-1),
    right: theme.spacing(-1)
  }
}))
function fontLinkToBase64 (link) {
  return new Promise((resolve, reject) => {
    axios.get(link).then(resp => {
      const contentType = resp.headers['content-type']
      const base64Data = 'data:' + contentType + ';charset=utf-8;base64,' + btoa(unescape(encodeURIComponent(resp.data)))
      const dataObj = {
        link: link,
        base64: base64Data
      }
      console.log(resp)
      resolve(dataObj)
    })
  }
  )
}

function getProcessedCSS (css) {
  return new Promise(function (resolve, reject) {
    const fontLinkRegex = new RegExp(/(https?:\/\/(fonts\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})\w/, 'ig')
    const matches = css.match(fontLinkRegex)

    // BUG: the last url always throws errors when converted to base64 for some reason.
    matches.splice(-1, 1)

    const promises = []

    matches.forEach(function (link) {
      promises.push(fontLinkToBase64(link))
    })

    Promise.all(promises).then(function (fontData) {
      fontData.forEach(function (data) {
        css = css.replace(data.link, data.base64)
      })
      resolve(css)
    })
  })
}
// todo make this seperate component file
function TextSvg ({ edit, selectedElementId, elem, setSelectedElementId, svgBoxDimensions }) {
  const [processedFontCSS, setProcessedFontCSS] = React.useState('')
  // todo add the element dimentions and edit box to SvgEditElement
  const [textDimentions, setTextDimentions] = React.useState()
  const textRef = React.useRef(null)
  React.useEffect(() => {
    if (textRef) {
      setTextDimentions(textRef.current.getBoundingClientRect())
    }
  }, [elem, textRef])
  React.useEffect(() => {
    axios.get(`https://fonts.googleapis.com/css?family=${encodeURI(elem.style.fontFamilyName)}&display=swap`).then(data => {
      getProcessedCSS(data.data).then(css => {
        console.log(css)
        setProcessedFontCSS(css)
      })
    })
  }, [elem.style.fontFamilyName])
  return (
    <g>

      <g>
        <style type='text/css'>
          {processedFontCSS}
          {`#${elem.id}{ 
          font-family: ${elem.style.fontFamilyName}
          line-height: 70%
          }`}
        </style>
        <defs />
        <text
          ref={textRef}
          onClick={() => setSelectedElementId(elem.id)}
          x={elem.coordinates.x}
          y={elem.coordinates.y}
          id={elem.id}
          fontFamily={elem.style.fontFamilyName}
          fill='black'
          style={{
            fontSize: elem.style.fontSize,
            cursor: 'pointer'
          }}
        >
          {elem.value}
        </text>
        {textDimentions && selectedElementId === elem.id && (
          <rect
            x={elem.coordinates.x}
            y={elem.coordinates.y - ((textDimentions.height * 1440) / svgBoxDimensions.height) + (((textDimentions.height * 1440) / svgBoxDimensions.height) * 0.22)}
            width={(textDimentions.width * 1440) / svgBoxDimensions.width}
            height={(textDimentions.height * 1440) / svgBoxDimensions.height}
            style={{ fill: 'none', stroke: 'blue' }}
            strokeWidth='.08%'
            strokeDasharray='13,13'
          />
        )}
      </g>
    </g>
  )
}
function ImageSvg ({ edit, selectedElementId, elem, setSelectedElementId, svgBoxDimensions }) {
  const [imageDimentions, setImageDimentions] = React.useState()
  const textRef = React.useRef(null)
  React.useEffect(() => {
    if (textRef) {
      setImageDimentions(textRef.current.getBoundingClientRect())
    }
  }, [elem, textRef])
  return (
    <svg width={elem.style.width} height='auto' x={elem.coordinates.x} y={elem.coordinates.y - ((elem.style.width * 4) / 20)} onClick={() => setSelectedElementId(elem.id)} viewBox={elem.viewBox}>
      <svg ref={textRef} dangerouslySetInnerHTML={{ __html: elem.svgString }} />
      {imageDimentions && selectedElementId === elem.id && (
        <rect
          width={elem.viewBox.split(' ')[2] - 20}
          height={elem.viewBox.split(' ')[3] - 20}
          // height={(imageDimentions.height * 3440) / svgBoxDimensions.height}
          style={{ fill: 'none', stroke: 'blue' }}
          strokeWidth='.8%'
          strokeDasharray='65,65'
        />
      )}
    </svg>
  )
}
function SvgEditElement ({ edit, elem, handleClick, onClick, selectedElementId, setSelectedElementId, svgBoxDimensions }) {
  switch (elem.type) {
    case 'TEXT':
      return <TextSvg edit={edit} svgBoxDimensions={svgBoxDimensions} selectedElementId={selectedElementId} handleClick={handleClick} onClick={onClick} setSelectedElementId={setSelectedElementId} elem={elem} />
    case 'IMAGE':
      return <ImageSvg edit={edit} svgBoxDimensions={svgBoxDimensions} selectedElementId={selectedElementId} handleClick={handleClick} onClick={onClick} setSelectedElementId={setSelectedElementId} elem={elem} />
    default:
      return null
  }
}



function PosterSvgRenderer ({ posterMapRef, setPosterDimentions = () => {}, base64OfMap, fontFamily, tinyText, subtitle, secondSubtitle, letter, onAddButtonClick, editable, selectedElementId, setSelectedElementId, selectSvgElement, svgData = {}, setSvgRef = () => {} }) {
  const [svgBoxDimensions, setSvgBoxDimensions] = React.useState()
  const [processedFontCSS, setProcessedFontCSS] = React.useState()
  const posterRef = React.useRef(null)
  const svgBoxRef = React.useRef(null)

  React.useEffect(() => {
    if (posterRef) {
      console.log(posterRef.current.getBoundingClientRect())
      setPosterDimentions(posterRef.current.getBoundingClientRect())
    }
  }, [posterRef, setPosterDimentions])

  React.useEffect(() => {
    if (fontFamily) {
      axios.get(`https://fonts.googleapis.com/css?family=${encodeURI(fontFamily)}&display=swap`).then(data => {
        getProcessedCSS(data.data).then(css => {
          console.log(css)
          setProcessedFontCSS(css)
        })
      })
    }
  }, [fontFamily])
  React.useEffect(() => {
    if (svgBoxRef.current) {
      setSvgBoxDimensions(svgBoxRef.current.getBoundingClientRect())
    }
  }, [svgBoxRef])

  const classes = useStyles()
  return (
    <div style={{ position: 'relative' }}>
      {editable ? (
        <>
          <svg viewBox='0 0 1440 1440' ref={svgBoxRef}>
            <svg height={3440} width={3440} x={-860} y={-860}>
              <rect id='background-to-switch' x='0' y='0' width={3440} height={3440} fill='white' />
              <g style={{ userSelect: 'none' }}>
                {svgData?.elements?.map(elem => (
                  <SvgEditElement edit svgBoxDimensions={svgBoxDimensions} selectedElementId={selectedElementId} setSelectedElementId={setSelectedElementId} key={elem.id} elem={elem} />
                ))}
              </g>
            </svg>
          </svg>
          <Fab onClick={onAddButtonClick} size='small' color='primary' className={classes.fab}>
            <AddIcon />
          </Fab>
        </>
      ) : (
        <Paper ref={posterRef} elevation={4} style={{ display: 'inline-block', width: '100%' }}>
          <svg viewBox='0 0 7200 10800' width={'100%'}>
            <svg ref={ref => setSvgRef(ref)} height={10800} width={7200}>
              <svg ref={posterMapRef} height={10800} width={7200}>
                <defs>
                  <style>
                    {processedFontCSS}
                    {`text {
                  font-size: 14px;
                  line-height: 75%
                  }`}
                    {`#main-letter { 
          font-family: ${fontFamily}
          }`}
                  </style>
                  <mask id='knockout-text'>
                    <rect width={7200} height={10800} fill='#fff' x='0' y='0' />
                    {/* // map out elements here */}
                    {svgData?.elements?.map(elem => (
                      <SvgEditElement edit={false} selectedElementId='' setSelectedElementId={() => {}} key={elem.id} elem={elem} />
                    ))}
                    <text id={"main-letter"} x='50%' y='48%' style={{ fontSize: '7500px' }} dominantBaseline='middle' textAnchor='middle'>{letter}</text>

                  </mask>
                </defs>
                <g style={{ userSelect: 'none' }}>
                  <rect id='background-to-switch' x='0' y='0' width={7200} height={10800} fill='black' />
                  { base64OfMap && <image href={base64OfMap} height={10800} width={'100%'}/>}
                  {/* <g dangerouslySetInnerHTML={{ __html: backgroundText }} /> */}
                  {/* insert example background text here */}
                  {/*<foreignObject x='0' y='0' width={7200} height={10800}>*/}
                  {/*  <Map*/}
                  {/*  onMoveEnd={(map, event) => {*/}
                  {/*  }}*/}
                  {/*  style='mapbox://styles/jaytonbirch/ckboaf0az1p681ipebhmmvh6w'*/}
                  {/*  containerStyle={{*/}
                  {/*    height: '10800px',*/}
                  {/*    width: '7200px'*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*</foreignObject>*/}
                  <rect
                    className='knockout-text-bg'
                    width={7200}
                    height={10800}
                    fill='#ffffff'
                    x='0'
                    y='0'
                    fillOpacity='1'
                    mask='url(#knockout-text)'
                  />
                  <text x='50%' y='85%' style={{ fontSize: '230px' }} dominantBaseline='middle' textAnchor='middle'>{subtitle}</text>
                  <text x='50%' y='87.2%' style={{ fontSize: '160px' }} dominantBaseline='middle' textAnchor='middle'>{secondSubtitle}</text>
                </g>
              </svg>
            </svg>
          </svg>
        </Paper>
      )}

    </div>
  )
}

export default PosterSvgRenderer
