import React from 'react'
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import MainStepper from './MainStepper/MainStepper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Alert from '@material-ui/lab/Alert'
import {
  Button,
  Card,
  CardContent,
  Grid,
  MobileStepper,
  useMediaQuery
} from '@material-ui/core'
import MainAppBar from './MainAppBar/MainAppBar'
import AddElementModal from './AddElementModal/AddElementModal'
import ProgressDialog from './ProgressDialog/ProgressDialog'
import SVGRenderer from './SVGRenderer/SVGRenderer'
import ElementEditor from './ElementEditor/ElementEditor'
import ElementButtonControls from './ElementButtonControls/ElementButtonControls'
import SvgPreview from './SvgPreview/SvgPreview'
import AddEditTinyText from './AddEditTinyText/AddEditTinyText'
import PreviewProgressDialog from './PreviewProgressDialog/PreviewProgressDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  textField: { },
  grid: {
    padding: theme.spacing(2)
  },
  stepper: {
    // margin: theme.spacing(2)
    backgroundColor: '#dadde3'
  },
  media: {
    height: 140
  },
  card: {
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}))
const startingTemplate = {
  elements: [
    {
      id: 'gw45hhw45y',
      type: 'TEXT',
      value: 'Halpert',
      coordinates: {
        x: 1135.2,
        y: 1651.2
      },
      style: {
        fontSize: 330,
        fontFamilyName: 'Lobster Two'
      },
      selected: false
    },
    {
      id: 'kjn',
      type: 'TEXT',
      value: 'est. 2009',
      coordinates: {
        x: 1479.2,
        y: 1788.8
      },
      style: {
        fontSize: 120,
        fontFamilyName: 'Lobster Two'
      },
      selected: false
    }
  ]
}

function getElementById (elements, id) {
  return elements.find(elem => elem.id === id)
}
// function getMobileOperatingSystem () {
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera
//
//   // Windows Phone must come first because its UA also contains "Android"
//   if (/windows phone/i.test(userAgent)) {
//     return 'Windows Phone'
//   }
//
//   if (/android/i.test(userAgent)) {
//     return 'Android'
//   }
//
//   // iOS detection from: http://stackoverflow.com/a/9039885/177710
//   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//     return 'iOS'
//   }
//
//   return 'unknown'
// }
// function renderTitle (activeStep) {
//   if (activeStep === 0) {
//     return 'Create the Main Text'
//   }
//   if (activeStep === 1) {
//     return 'Choose the Tiny Text'
//   }
//   if (activeStep === 2) {
//     return 'Create Your Print'
//   }
//   if (activeStep === 3) {
//     return 'Done!'
//   }
// }

function WordApp () {
  // to use a reducer for text data
  const [svgData, setSvgData] = React.useState(startingTemplate)
  const [tinyText, setTinyText] = React.useState('')
  const [previewImg, setPreviewImg] = React.useState('')
  const [fetching, setFetching] = React.useState(false)
  const [resolvedFetch, setResolvedFetch] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [svgRef, setSvgRef] = React.useState(null)
  const [selectedElementId, setSelectedElementId] = React.useState('')
  const [openAddModal, setOpenAddModal] = React.useState(false)
  const [imageId, setImageId] = React.useState('')
  const classes = useStyles()
  const isBigScreen = useMediaQuery('(min-width:600px)')
  console.log('process.env', process.env)
  const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://tiny-text.herokuapp.com'
  React.useEffect(() => {
    if (tinyText) {
      setActiveStep(2)
    }
  }, [tinyText])
  React.useEffect(() => {
    if (tinyText && svgRef && activeStep === 2) {
      // clear any previous preview
      setPreviewImg(null)
      // fetch the preview
      const s = new XMLSerializer()
      const str = s.serializeToString(svgRef)
      const request = { backgroundText: tinyText, svg: str }
      axios.post(BASE_URL + '/createpreview', request,
        { headers: { 'Content-Type': 'application/json' }, responseType: 'arraybuffer' })
        // .then(resp => console.log(Buffer.from(resp.data, 'binary').toString('base64')))
        .then(resp => {
          setResolvedFetch(true)
          setPreviewImg(Buffer.from(resp.data, 'binary').toString('base64'))
          // setActiveStep(2)
        }).catch(e => {
          setResolvedFetch(true)
          alert(`Something went wrong: ${e}`)
        }
        )
      // setActiveStep(2)
    }
  }, [tinyText, svgRef, activeStep, BASE_URL])

  React.useEffect(() => {
    console.log('activeStep: ', activeStep)
  }, [activeStep])

  function addTextElement () {
    const newTextElementId = `${Date.now().toString()}-textElement`
    const newTextElement = {
      id: newTextElementId,
      type: 'TEXT',
      value: 'Text',
      coordinates: { x: 620, y: 1685 },
      style: { fontSize: 750, fontFamilyName: 'Roboto' },
      selected: false
    }
    setSvgData(prev => ({ ...prev, elements: [...prev.elements, newTextElement] }))
    setSelectedElementId(newTextElementId)
    setOpenAddModal(false)
  }
  function addImageElement (svg, viewBox) {
    const newImageElementId = `${Date.now().toString()}-ImageElement`
    const newImageElement = {
      id: newImageElementId,
      type: 'IMAGE',
      svgString: svg,
      viewBox,
      coordinates: { x: 1169.6, y: 68.8 },
      style: { width: '825' },
      selected: false
    }
    setSvgData(prev => ({ ...prev, elements: [...prev.elements, newImageElement] }))
    setSelectedElementId(newImageElementId)
    setOpenAddModal(false)
  }
  function handleElemChange (elem) {
    const newSvgData = { ...svgData }
    const elemIndex = svgData.elements.findIndex(e => e.id === elem.id)
    newSvgData.elements[elemIndex] = elem
    setSvgData(newSvgData)
  }
  function handleElementCopy (elem) {
    const copyElem = { ...elem, id: Date.now().toString(), coordinates: { x: elem.coordinates.x + 160, y: elem.coordinates.y + 160 } }
    setSvgData(prev => ({ ...prev, elements: [...prev.elements, copyElem] }))
  }
  function handleElementDelete (elem) {
    const oldElements = svgData.elements
    const elemToDeleteIndex = oldElements.findIndex(e => e === elem.id)
    oldElements.splice(elemToDeleteIndex, 1)
    setSelectedElementId('')
    setSvgData(prev => ({ ...prev, elements: [...oldElements] }))
  }
  async function download () {
    setFetching(true)
    setResolvedFetch(false)
    const s = new XMLSerializer()
    const str = s.serializeToString(svgRef)
    const request = { backgroundText: tinyText, svg: str }
    axios.post(BASE_URL + '/createsvg', request,
      { headers: { 'Content-Type': 'application/json' } })
      .then(resp => {
        console.log(resp.body)
        setImageId(resp.data.image_id)
        setResolvedFetch(true)
        setActiveStep(3)
      }).catch(e => {
        setResolvedFetch(true)
        alert(`Something went wrong: ${e}`)
      }
      )
  }

  React.useEffect(() => {
    console.log('did this load', svgRef)
  }, [svgRef])

  return (
    <div
      css={css`background-color: #dadde3; height: 100%; min-height: 100vh; display: flex; flex-direction
: column`} className={classes.root}
    >
      <AddElementModal open={openAddModal} onClose={() => setOpenAddModal(false)} onAddTextElement={addTextElement} onAddImage={addImageElement} />
      {fetching && <ProgressDialog imageId={imageId} loading={fetching} setLoading={setFetching} fetchResolved={resolvedFetch} />}
      <MainAppBar />
      {isBigScreen ? <MainStepper activeStep={activeStep} /> : (<MobileStepper
        backButton={<Button size='small' onClick={() => setActiveStep(prev => prev - 1)} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
          Back
        </Button>}
        nextButton={
          <Button
            size='small'
            onClick={() => setActiveStep(prev => prev + 1)}
            disabled={activeStep === 2 || (activeStep === 1 && !tinyText)}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        steps={3}
        position='static'
        activeStep={activeStep}
      />)}
      <div css={css`flex-grow: 1`}>
        <Grid className={classes.grid} justify='center' direction='row-reverse' container spacing={2}>
          {/* <MainStepper activeStep={activeStep} /> */}
          <Grid item lg={5} xs={10}>
            {activeStep >= 2 ? <SvgPreview src={previewImg} /> : (
              <SVGRenderer
                setSvgRef={setSvgRef}
                svgData={svgData}
                selectedElementId={selectedElementId}
                setSelectedElementId={setSelectedElementId}
                editable={activeStep === 0}
                onAddButtonClick={() => setOpenAddModal(true)}
                tinyText={tinyText}
              />

            )}
          </Grid>
          <Grid item lg={5} xs={12}>
            {activeStep === 0 && (
              <React.Fragment>
                {selectedElementId && <ElementButtonControls elem={getElementById(svgData.elements, selectedElementId)} onElementCopy={handleElementCopy} onElementDelete={handleElementDelete} />}
                <Card style={{ overflow: 'visible' }}>
                  <CardContent>
                    {selectedElementId ? <ElementEditor onElementChange={handleElemChange} elem={getElementById(svgData.elements, selectedElementId)} /> : 'Select something to edit'}
                  </CardContent>
                </Card>
              </React.Fragment>
            )}
            {activeStep === 1 && (
              <React.Fragment>
                <AddEditTinyText tinyText={tinyText} setTinyText={setTinyText} />
              </React.Fragment>
            )}
            {activeStep === 2 && (
              <React.Fragment>
                {previewImg ? (
                  <Grid container justify='center' spacing={2}>
                    <Grid item>
                      <Alert severity='warning'>Low Quality Preview - Purchase For Full Quality</Alert>
                    </Grid>
                    <Grid item>
                      <Button onClick={download} color='primary' variant='contained'>Create and Add to Cart</Button>
                    </Grid>
                  </Grid>
                ) : <PreviewProgressDialog />}
              </React.Fragment>

            )}
            {/* add next and back buttons for big screen here */}
            {isBigScreen && (
              <Grid container justify='space-between' alignContent='flex-end' style={{ paddingTop: '12px', width: '100%' }}>
                <Button style={{ visibility: activeStep === 0 && 'hidden' }} variant='outlined' color='primary' onClick={() => setActiveStep(prev => prev - 1)}>Back</Button>
                <Button disabled={activeStep === 2 || (activeStep === 1 && !tinyText)} variant='contained' color='primary' onClick={() => setActiveStep(prev => prev + 1)}>Next</Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>

    </div>
  )
}

export default WordApp
