const mapStyles = [
  {
    name: 'Minimalist',
    fontFamily: 'Open Sans',
    avatarPath: 'plain_white_avatar.png',
    subStyles: [
      {
        hex: '#C0392B',
        url: 'mapbox://styles/jaytonbirch/ckjdcw38ncqt419lhlamsm4tn'
      },
      {
        hex: '#E74C3C',
        url: 'mapbox://styles/jaytonbirch/ckjdc09f5296q1aldp9f0nuap'
      },
      {
        hex: '#9B59B6',
        url: 'mapbox://styles/jaytonbirch/ckjdc16qwcs0l19o5ibu76vkt'
      },
      {
        hex: '#8E44AD',
        url: 'mapbox://styles/jaytonbirch/ckjdc1wdj3ude1aqsd3h2l61v'
      },
      {
        hex: '#2980B9',
        url: 'mapbox://styles/jaytonbirch/ckjdc2kevcq0l19lhj9nz5491'
      },
      {
        hex: '#3498DB',
        url: 'mapbox://styles/jaytonbirch/ckjdc47l26o1d19qopv4c0xw7'
      },
      { hex: '#1ABC9C', url: 'mapbox://styles/jaytonbirch/ckjdc4yagcs1s19nq8fqmdh70' },
      { hex: '#16A085', url: 'mapbox://styles/jaytonbirch/ckjdc5uzz3uh51aqswjorco2r' },
      { hex: '#27AE60', url: 'mapbox://styles/jaytonbirch/ckjdc7vc06o4s19qo80mwo1rl' },
      { hex: '#2ECC71', url: 'mapbox://styles/jaytonbirch/ckjdc8o50cs5919nqun7xxdqy' },
      { hex: '#F1C40F', url: 'mapbox://styles/jaytonbirch/ckjdc99ci41i91anynryzwhlu' },
      { hex: '#F39C12', url: 'mapbox://styles/jaytonbirch/ckjdca0nn3ul41aqs8i1ytabt' },
      { hex: '#E67E22', url: 'mapbox://styles/jaytonbirch/ckjdcaltncq8i19lhlilzniax' },
      { hex: '#D35400', url: 'mapbox://styles/jaytonbirch/ckjdcb3iy5d021apjvlpjvph9' },
      { hex: '#ECF0F1', url: 'mapbox://styles/jaytonbirch/ckjdcbqr26o8r19qom0cj57lv' },
      { hex: '#BDC3C7', url: 'mapbox://styles/jaytonbirch/ckjdcck7l41lu1anyndiuwfaq' },
      { hex: '#95A5A6', url: 'mapbox://styles/jaytonbirch/ckjdcfc4u14mx19nxc5e24c61' },
      { hex: '#7F8C8D', url: 'mapbox://styles/jaytonbirch/ckjdcg2jr3uqx1aqsrafvzo07' },
      { hex: '#34495E', url: 'mapbox://styles/jaytonbirch/ckjdcgn1pcqeh19lhribobdy7' },
      { hex: '#2C3E50', url: 'mapbox://styles/jaytonbirch/ckjdch8wq6nvp19mlgp5b92w4' }
    ]

  },
  {
    name: 'Satellite',
    fontFamily: 'Open Sans',
    avatarPath: 'dark_pale_avatar.png',
    subStyles: [
      { hex: '#C0392B', url: 'mapbox://styles/jaytonbirch/ckj7qrzhu17tl1aluldpp8uo3' },
      { hex: '#E74C3C', url: 'mapbox://styles/jaytonbirch/ckjd94kqu6kpx1amskgr0tox1' },
      { hex: '#9B59B6', url: 'mapbox://styles/jaytonbirch/ckjd96z356kwp1aluawz59i8a' },
      { hex: '#8E44AD', url: 'mapbox://styles/jaytonbirch/ckjd98afc2y4m19mgowlgg67m' },
      { hex: '#2980B9', url: 'mapbox://styles/jaytonbirch/ckjd9a6pk267i19p6it6j0iem' },
      { hex: '#3498DB', url: 'mapbox://styles/jaytonbirch/ckjd9fychcnfg19lhzzxf9ful' },
      { hex: '#1ABC9C', url: 'mapbox://styles/jaytonbirch/ckjd9h7bx2yd519mgt0k4wpjy' },
      { hex: '#16A085', url: 'mapbox://styles/jaytonbirch/ckjd9jn073ryh1aqsailmqqbj' },
      { hex: '#27AE60', url: 'mapbox://styles/jaytonbirch/ckjdeh5mh2b9e19p6fyqms5ph' },
      { hex: '#2ECC71', url: 'mapbox://styles/jaytonbirch/ckjd9ky2g6l9o1aluvwbrdzjd' },
      { hex: '#F1C40F', url: 'mapbox://styles/jaytonbirch/ckjdadimb6c3d19o0i075hjm7' },
      { hex: '#F39C12', url: 'mapbox://styles/jaytonbirch/ckjdbcvhe28kr1aldm0gj272o' },
      { hex: '#E67E22', url: 'mapbox://styles/jaytonbirch/ckjdae5q87mn519lazylv4u00' },
      { hex: '#D35400', url: 'mapbox://styles/jaytonbirch/ckjdafo8o27z619phmzsh0cvn' },
      { hex: '#ECF0F1', url: 'mapbox://styles/jaytonbirch/ckjdahrs76ec419qscix2gcg9' },
      { hex: '#BDC3C7', url: 'mapbox://styles/jaytonbirch/ckjdailsc6cbi19mcbr15r3mq' },
      { hex: '#95A5A6', url: 'mapbox://styles/jaytonbirch/ckjdaono699qi1al7zq0ymgd5' },
      { hex: '#7F8C8D', url: 'mapbox://styles/jaytonbirch/ckjdaplgecqo619nqo0q8evti' },
      { hex: '#34495E', url: 'mapbox://styles/jaytonbirch/ckjdaqn4acqqq19o5c9hmoslz' },
      { hex: '#2C3E50', url: 'mapbox://styles/jaytonbirch/ckjdartmy6elh19qsq8k6doi1' },
      { hex: '#ff7700', name: 'Nickelodeon Orange', url: 'mapbox://styles/jaytonbirch/cklqw3ory0xhw17nvgr95em8m' }
    ]
  }
]

export default mapStyles
