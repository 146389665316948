import React from 'react'
import {
  Router,
  Switch,
  Route
} from 'react-router'
import WordApp from './WordApp'
import LandingPage from './LandingPage/LandingPage'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { createBrowserHistory } from 'history'
import firebase from 'firebase'
import firebaseConfig from './firebase.config'
import PosterBuilder from './PosterBuilder/PosterBuilder'
import LetterMapBuilder from './LetterMapBuilder/LetterMapBuilder'
import RegMapPosterBuilder from './RegMapPosterBuilder/RegMapPosterBuilder'
import Client from 'shopify-buy'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Badge, IconButton } from '@material-ui/core'
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons'
import DownloadPage from './DownloadPage/DownloadPage'
import ShoppingCart from './ShoppingCart/ShoppingCart'
import AddToCartConfirmation from './AddToCartConfirmation/AddToCartConfirmation'
// import Gedcom from './Gedcom'
// todo detect device and do new tab for ios
firebase.initializeApp(firebaseConfig)
export const AuthContext = React.createContext(null)
function Copyright () {
  return (
    <Typography variant='body2' color='white' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Inkee Tree
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
const client = Client.buildClient({
  domain: 'checkout.inkeetree.com',
  storefrontAccessToken: 'ce71288415400ce84bb7f81e6aa5823a'
})
//LOGO COLORS
// salmon: #ff6b6b
// green: #8bc53f
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#73a334',
    padding: theme.spacing(6),
    color: 'white'
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    // Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    // fontFamily: ['Helvetica', '"Helvetica Neue"', 'Arial', '"Lucinda Grande"', 'sans-serif'],
    fontFamily: ['Bungee'],
    // letterSpacing: '0.1em',
    fontSize: '1.33333em',
    // fontWeight: '700',
    cursor: 'pointer',
  }
}))

function getCartCount (lineItems){
  let count = 0
  if (lineItems && lineItems.length > 0) {
    lineItems.forEach(lineItem => count = count + lineItem.quantity)
  }
  return count
}

const customHistory = createBrowserHistory()

function cartReducer(state, action) {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCHED': {
      return {
        error: null,
        loading: false,
        checkout: {...action.checkout},
        cartCount: getCartCount(action?.checkout?.lineItems)
      }
    }
    case 'FETCH_ERROR': {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
export default function App () {
  const [cartIsOpen, setCartIsOpen] = React.useState(false)
  const [confirmAddIsOpen, setConfirmAddIsOpen] = React.useState(false)
  const [cartState, dispatchCart] = React.useReducer(cartReducer, {checkout: {}, loading: false})
  const classes = useStyles()

  function addItemsToCart(lineItemsToAdd) {
    dispatchCart({type: 'FETCHING'})
    setConfirmAddIsOpen(true)
    client.checkout.addLineItems(cartState.checkout.id, lineItemsToAdd).then(checkout => {
      dispatchCart({type: 'FETCHED', checkout})
    })
  }

  function removeItemFromCart(lineItemToRemove){
    dispatchCart({type: 'FETCHING'})
    client.checkout.removeLineItems(cartState.checkout.id, [lineItemToRemove]).then(checkout => {
      dispatchCart({type: 'FETCHED', checkout})
    })
  }

  React.useEffect(() => {
    if (cartState.checkout.completedAt) {
      client.checkout.create().then(newCheckout => {
        localStorage.setItem('checkoutId', newCheckout.id)
        dispatchCart({type: 'FETCHED', checkout: newCheckout})
      })
    }
  },[cartState.checkout.completedAt])

  console.log('cartState', cartState)
  React.useEffect(() => {
    //check if checkout exists in storage already
    const checkoutId = localStorage.getItem('checkoutId');
    if (checkoutId){
      client.checkout.fetch(checkoutId).then((newCheckout) => {
        //todo check here if it is completed
        dispatchCart({type: 'FETCHED', checkout: newCheckout})
      });
    } else {
    client.checkout.create().then(newCheckout => {
      localStorage.setItem('checkoutId', newCheckout.id)
      dispatchCart({type: 'FETCHED', checkout: newCheckout})
    })
    }
  }, [])

  const theme = React.useMemo(
    () =>
      createMuiTheme({

        breakpoints: {
          keys: [
            'xs',
            'sm',
            'md',
            'lg',
            'xl'
          ],
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
          }
        },
        direction: 'ltr',
        mixins: {
          toolbar: {
            minHeight: 56,
            '@media (min-width:0px) and (orientation: landscape)': {
              minHeight: 48
            },
            '@media (min-width:600px)': {
              minHeight: 64
            }
          }
        },
        overrides: {},
        palette: {
          common: {
            black: '#000',
            white: '#fff'
          },
          type: 'light',
          primary: {
            main: '#73a334',
            light: 'rgb(51, 171, 159)',
            dark: 'rgb(0, 105, 95)',
            contrastText: '#fff'
          },
          secondary: {
            main: '#ff6b6b',
            light: 'rgb(247, 51, 120)',
            dark: 'rgb(171, 0, 60)',
            contrastText: '#fff'
          },
          error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff'
          },
          warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: 'rgba(0, 0, 0, 0.87)'
          },
          info: {
            light: '#64b5f6',
            main: '#2196f3',
            dark: '#1976d2',
            contrastText: '#fff'
          },
          success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: 'rgba(0, 0, 0, 0.87)'
          },
          grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161'
          },
          contrastThreshold: 3,
          tonalOffset: 0.2,
          text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)'
          },
          divider: 'rgba(0, 0, 0, 0.12)',
          background: {
            paper: '#fff',
            default: '#fff',
            level2: '#f5f5f5',
            level1: '#fff'
          },
          action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            hoverOpacity: 0.04,
            selected: 'rgba(0, 0, 0, 0.08)',
            selectedOpacity: 0.08,
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(0, 0, 0, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12
          }
        },
        props: {},
        shadows: [
          'none',
          '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
          '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
          '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
          '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
          '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
          '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
          '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
          '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
          '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
          '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
          '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
          '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
          '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
          '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
          '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
          '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
          '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
          '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
          '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
          '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
          '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
          '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
          '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
          '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
        ],
        typography: {
          htmlFontSize: 16,
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
          fontWeightBold: 700,
          h1: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
            fontSize: '6rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em'
          },
          h2: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
            fontSize: '3.75rem',
            lineHeight: 1.2,
            letterSpacing: '-0.00833em'
          },
          h3: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '3rem',
            lineHeight: 1.167,
            letterSpacing: '0em'
          },
          h4: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '2.125rem',
            lineHeight: 1.235,
            letterSpacing: '0.00735em'
          },
          h5: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: 1.334,
            letterSpacing: '0em'
          },
          h6: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.6,
            letterSpacing: '0.0075em'
          },
          subtitle1: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em'
          },
          subtitle2: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.57,
            letterSpacing: '0.00714em'
          },
          body1: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em'
          },
          body2: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
          },
          button: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 1.75,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase'
          },
          caption: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.66,
            letterSpacing: '0.03333em'
          },
          overline: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 2.66,
            letterSpacing: '0.08333em',
            textTransform: 'uppercase'
          }
        },
        shape: {
          borderRadius: 4
        },
        transitions: {
          easing: {
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
          },
          duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195
          }
        },
        zIndex: {
          mobileStepper: 1000,
          speedDial: 1050,
          appBar: 1100,
          drawer: 1200,
          modal: 1300,
          snackbar: 1400,
          tooltip: 1500
        },
        nprogress: {
          color: '#000'
        }

      }),
    []
  )
  return (
      <ThemeProvider theme={theme}>
        {/*<CssBaseline />*/}
        <AppBar position='relative'>
          <Toolbar>
            <Typography onClick={() => window.location.href = "/"} variant='h6' color='inherit' className={classes.title} noWrap>
              Inkee Tree
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton onClick={() => setCartIsOpen(true)} aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={cartState?.cartCount} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Router history={customHistory}>
          <Switch>
            {/*<Route path='/family'>*/}
            {/*  <Gedcom/>*/}
            {/*</Route>*/}
            <Route path='/download'>
              <DownloadPage/>
            </Route>
            <Route path='/builder'>
              <WordApp firebase={firebase} />
            </Route>
            <Route path='/poster'>
              <PosterBuilder client={client} />
            </Route>
            <Route path='/letter-map'>
              <LetterMapBuilder />
            </Route>
            <Route path='/map-builder'>
              <RegMapPosterBuilder client={client} addItemsToCart={addItemsToCart}/>
            </Route>
            <Route path='/'>
              <LandingPage client={client} />
            </Route>
          </Switch>
        </Router>
        <ShoppingCart cartState={cartState} open={cartIsOpen} setCartIsOpen={setCartIsOpen} removeItemFromCart={removeItemFromCart}/>
        <AddToCartConfirmation cartState={cartState} setCartIsOpen={setCartIsOpen} setConfirmAddIsOpen={setConfirmAddIsOpen} open={confirmAddIsOpen}/>
        {/*<AddToCartConfirmation cartState={{loading: true}} setCartIsOpen={setCartIsOpen} setConfirmAddIsOpen={setConfirmAddIsOpen} open={true}/>*/}
        <footer className={classes.footer}>
          <Typography variant='h6' align='center' gutterBottom>
            We are in BETA!
          </Typography>
          <Typography variant='subtitle1' align='center' color='color' component='p'>
            Send any feedback, bugs, issues to feedback@inkeetree.com
          </Typography>
          <Copyright />
        </footer>
      </ThemeProvider>
  )
}
