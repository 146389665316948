import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import fontFamilies from '../fontFamilies'
import {
  FormControlLabel,
  InputLabel,
  ListItemText,
  Checkbox,
  Button,
  Select,
  MenuItem,
  ListItemAvatar,
  Avatar,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  useMediaQuery
  , Grid, ListItem, FormControl
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import PlaceAutocomplete from '../PlaceAutocomplete/PlaceAutocomplete'
import { Add, Remove, Room, TextFields, ColorLens, PhotoSizeSelectLarge, Image } from '@material-ui/icons'
import mapStyles from '../mapStyles'
import axios from 'axios'

const PRODUCT_DATA = {
  SIZES: ['24" x 36"', '20" x 30"', '12" x 18"'],
  TYPES: ['Premium Matte Poster', 'Download Only']
}

const BUY_VARIANTS = [
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDAxNzMyMA==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[0],
    price: '$10'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDA4Mjg1Ng==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[1],
    price: '$10'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDE0ODM5Mg==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[2],
    price: '$10'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM3OTk4NDU1Mg==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[0],
    price: '$30'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDA1MDA4OA==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[1],
    price: '$24'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDExNTYyNA==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[2],
    price: '$17'
  }
]

function alertMessage (type, size) {
  if (type === 'Download Only') {
    return `This is a high quality PNG file. Ideal for printing a size of ${size}`
  } else {
    return 'Digital download included'
  }
}

function TypeSelector ({ setItemData, itemData }) {
  const [type, setType] = React.useState('Download Only')
  const [size, setSize] = React.useState('24" x 36"')

  React.useEffect(() => {
    const matchingVariant = BUY_VARIANTS.find(variant => {
      return type === variant.type && variant.size === size
    })
    setItemData(prev => ({ ...prev, buyVariant: matchingVariant }))
  }, [setItemData, type, size])

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Type</FormLabel>
          <RadioGroup aria-label='product-type' name='type' value={type} onChange={(event) => setType(event.target.value)}>
            <FormControlLabel value='Download Only' control={<Radio />} label='Download Only' />
            <FormControlLabel value='Premium Matte Poster' control={<Radio />} label='Premium Matte Poster' />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Size</FormLabel>
          <RadioGroup aria-label='product-size' name='size' value={size} onChange={(event) => setSize(event.target.value)}>
            <FormControlLabel value='12" x 18"' control={<Radio />} label={type === 'Premium Matte Poster' ? '12" x 18"' : '3600px x 5400px PNG'} />
            <FormControlLabel value='20" x 30"' control={<Radio />} label={type === 'Premium Matte Poster' ? '20" x 30"' : '6000px x 9000px PNG'} />
            <FormControlLabel value='24" x 36"' control={<Radio />} label={type === 'Premium Matte Poster' ? '24" x 36"' : '7200px x 10800px PNG'} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Alert severity='info'>
          {alertMessage(type, size)}
        </Alert>
      </Grid>
    </Grid>
  )
}
function StyleSelector ({ setItemData, itemData }) {
  const { mapStyle, styleCategory } = itemData
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <FormControl>
          <InputLabel id='map-theme-select-picker-label'>Map Theme</InputLabel>
          <Select
            defaultValue={mapStyles[0]}
            labelId='map-theme-select-picker-label'
            value={styleCategory}
            autoWidth
            onChange={event => {
              setItemData(prev => ({ ...prev, styleCategory: event.target.value, mapStyle: event.target.value.subStyles[0] }))
            }}
          >
            {mapStyles.map(styleObj => {
              //
              return (
                <MenuItem value={styleObj}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={styleObj.avatarPath} />
                    </ListItemAvatar>
                    <ListItemText>
                      <span style={{ fontFamily: [styleObj.fontFamily] }}>
                        {styleObj.name}
                      </span>
                    </ListItemText>
                  </ListItem>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      {styleCategory.subStyles && (
        <Grid item>
          <FormControl>
            <InputLabel id='map-theme-select-picker-label'>Color</InputLabel>
            <Select
              defaultValue={styleCategory.subStyles[0]}
              labelId='map-theme-select-picker-label'
              value={mapStyle}
              autoWidth
              onChange={event => {
                setItemData(prev => ({ ...prev, mapStyle: event.target.value }))
              }}
            >
              {styleCategory.subStyles.map(styleObj => {
                //
                return (
                  <MenuItem value={styleObj}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: styleObj.hex }}>{' '}</Avatar>
                      </ListItemAvatar>
                      <ListItemText>{styleObj.name ? styleObj.name : ''}</ListItemText>
                    </ListItem>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  )
}
function PlacePickerGroup ({ setItemData, itemData }) {
  const { showRouteSelect } = itemData
  React.useEffect(() => {
    if (!showRouteSelect && itemData.finalLocation) {
      setItemData(prev => ({ ...prev, finalLocation: null, finalLocationFormValue: null }))
    }
  }, [itemData.finalLocation, setItemData, showRouteSelect])
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <PlaceAutocomplete
          setCenter={(center) => {
            setItemData(prev => ({ ...prev, center, startingLocation: center }))
          }}
          value={itemData.startingLocationFormValue}
          setValue={(value) => setItemData(prev => ({ ...prev, startingLocationFormValue: value }))}
          label={showRouteSelect ? 'Starting Location' : 'Find a Location'}
          setTitle={(title) => setItemData(prev => ({ ...prev, title }))}
          setSubtitle={(subtitle) => setItemData(prev => ({ ...prev, subtitle }))}
        />
      </Grid>
      <Grid item>
        <SwipeableViews axis='x' index={showRouteSelect ? 1 : 0} disabled>
          <Alert severity='info'>You can also scroll and zoom on the map!</Alert>
          <PlaceAutocomplete
            setCenter={(center) => setItemData(prev => ({ ...prev, finalLocation: center }))}
            label='Destination'
            setTitle={(title) => setItemData(prev => ({ ...prev, title }))}
            setSubtitle={(subtitle) => setItemData(prev => ({ ...prev, subtitle }))}
            value={itemData.finalLocationFormValue}
            setValue={value => setItemData(prev => ({ ...prev, finalLocationFormValue: value }))}
          />
        </SwipeableViews>
      </Grid>
      <Grid item>
        <Button
          onClick={() => setItemData(prev => ({ ...prev, showRouteSelect: !prev.showRouteSelect }))}
          color={showRouteSelect ? 'secondary' : 'primary'}
          startIcon={showRouteSelect ? <Remove /> : <Add />}
        >
          {showRouteSelect ? 'Remove Destination' : 'Add Destination'}
        </Button>
      </Grid>
    </Grid>
  )
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  }
}))

function getRouteLine (startingLocation, finalLocation) {
  return axios
    .get(`https://api.mapbox.com/directions/v5/mapbox/driving/${startingLocation.lng},${startingLocation.lat};${finalLocation.lng},${finalLocation.lat}?access_token=${ACCESS_TOKEN}&geometries=geojson&overview=full`)
}

function FontPicker ({ onChange, itemData, value, label }) {
  return (
    <FormControl>
      <InputLabel id='font-picker-label'>{label}</InputLabel>
      <Select value={value} style={{ fontFamily: value }} onChange={onChange} labelId='font-picker-label'>
        {fontFamilies.map(fontFamily => {
          return (
            <MenuItem style={{ fontFamily: fontFamily.name }} value={fontFamily.name}>
              {fontFamily.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

function LabelForm ({ setItemData, itemData }) {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <TextField value={itemData.title} onChange={event => setItemData(prev => ({ ...prev, title: event.target.value }))} placeholder='Eagle Mountain' fullWidth label='Title' />
      </Grid>
      <Grid item>
        <FontPicker label='Title Font' value={itemData.fontFamily} onChange={event => setItemData(prev => ({ ...prev, fontFamily: event.target.value }))} itemData={itemData} />
      </Grid>
      <Grid item>
        <TextField value={itemData.subtitle} onChange={event => setItemData(prev => ({ ...prev, subtitle: event.target.value }))} placeholder='UT, USA' fullWidth label='Subtitle' />
      </Grid>
      <Grid item>
        <FontPicker label='Subtitle Font' value={itemData.secondaryFontFamily} onChange={event => setItemData(prev => ({ ...prev, secondaryFontFamily: event.target.value }))} itemData={itemData} />
      </Grid>
    </Grid>
  )
}
const ACCESS_TOKEN = 'pk.eyJ1IjoiamF5dG9uYmlyY2giLCJhIjoiY2tiamxnZXQzMG82OTJ6azA2c3VoODlxZSJ9.yf1iANJU2yxYLlLvzwpRUg'

export default function EditingTabs ({ itemData, setItemData }) {
  const [value, setValue] = React.useState(0)
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:600px)')
  const theme = useTheme()

  React.useEffect(() => {
    console.log('startingLocation: ', itemData.startingLocation)
    console.log('finalLocation: ', itemData.finalLocation)
    if (itemData.startingLocation && itemData.finalLocation) {
      getRouteLine(itemData.startingLocation, itemData.finalLocation)
        .then(res => {
          console.log(res.data)
          setItemData(prev => ({ ...prev, routeResponse: res.data }))
        })
        .catch(e => console.log(e))
    } else {
      setItemData(prev => ({ ...prev, routeResponse: null }))
    }
  }, [itemData.finalLocation, itemData.startingLocation, setItemData])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant={matches ? 'scrollable' : 'fullWidth'}
          aria-label='full width tabs example'
          scrollButtons='on'
        >
          <Tab label='Location' icon={<Room />} {...a11yProps(0)} />
          <Tab label='Style' icon={<ColorLens />} {...a11yProps(2)} />
          <Tab label='Labels' icon={<TextFields />} {...a11yProps(1)} />
          <Tab label='Finalize' icon={<PhotoSizeSelectLarge />} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        // style={{ height: 306 }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <PlacePickerGroup setItemData={setItemData} itemData={itemData} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <StyleSelector setItemData={setItemData} itemData={itemData} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <LabelForm itemData={itemData} setItemData={setItemData} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <TypeSelector setItemData={setItemData} itemData={itemData} />
        </TabPanel>
      </SwipeableViews>

    </div>
  )
}
