import React from 'react'
import TextElementEditor from '../TextElementEditor/TextElementEditor'
import ImageElementEditor from '../ImageElementEditor/ImageElementEditor'
function ElementEditor ({ elem, onElementChange }) {
  switch (elem.type) {
    case 'TEXT':
      return <TextElementEditor onElementChange={onElementChange} elem={elem} />
    case 'IMAGE':
      return <ImageElementEditor onElementChange={onElementChange} elem={elem} />
    default:
      return null
  }
}
export default ElementEditor
