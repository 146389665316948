import React from 'react'
import { Dialog, DialogTitle, DialogContent, LinearProgress, DialogContentText, DialogActions, Button } from '@material-ui/core'

function loadingText (completed) {
  if (completed < 10) {
    return 'Starting...'
  }
  if (completed < 40) {
    return 'Drawing the big stuff...'
  }
  if (completed < 80) {
    return 'Writing all the little text...'
  }
  if (completed < 101) {
    return 'Finishing up...'
  }
}

function ProgressDialog ({ loading, fetchResolved, setLoading, imageId }) {
  const [completed, setCompleted] = React.useState(0)
  React.useEffect(() => {
    function progress () {
      setCompleted((oldCompleted) => {
        if (fetchResolved && completed >= 90) {
          // const productData = { id: 34470935134376, quantity: 1, properties: { image_id: imageId } }
          // const productDataURI = encodeURI(JSON.stringify(productData))
          // window.location.href = `https://felix-ink.myshopify.com/pages/added-to-cart?q=${productDataURI}`
        }
        const diff = Math.random() * 10
        return Math.min(oldCompleted + diff, 90)
      })
    }
    const timer = setInterval(progress, 500)
    return () => {
      clearInterval(timer)
    }
  }, [completed, fetchResolved, imageId])
  return completed < 100 ? (
    <Dialog fullWidth maxWidth='md' open={loading}>
      <DialogTitle>{loadingText(completed)}</DialogTitle>
      <DialogContent>
        <LinearProgress variant='determinate' value={completed} />
      </DialogContent>
    </Dialog>

  ) : (
    <Dialog open={loading}>
      <DialogTitle>Completed!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          An email should arrive within a few minutes containing a large, high quality .png file of your TinyTextPrint!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const productData = { id: 34470935134376, quantity: 1, properties: { image_id: imageId } }
            const productDataURI = encodeURI(JSON.stringify(productData))
            window.location.href = `https://felix-ink.myshopify.com/pages/added-to-cart?q=${productDataURI}`
            setLoading(false)
          }} variant='contained' color='primary'
        >Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProgressDialog
