import React from 'react'
import { IconButton, ButtonGroup, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}))
function ElementButtonControls ({ elem, onElementCopy, onElementDelete, onAddElement }) {
  const classes = useStyles()
  return (
    <Grid container spacing={2} className={classes.root} justify='center' alignItems='center'>
      <Grid item>
        <ButtonGroup variant='outlined'>
          <IconButton onClick={() => onElementCopy(elem)}>
            <FileCopyIcon />
          </IconButton>
          <IconButton onClick={() => onElementDelete(elem)}>
            <DeleteIcon />
          </IconButton>
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}

export default ElementButtonControls
