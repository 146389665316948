import React from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowForward } from '@material-ui/icons'
import {
  AppBar,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core'
import PlaceAutocomplete from '../PlaceAutocomplete/PlaceAutocomplete'
import ReactMapboxGl, { GeoJSONLayer, MapContext } from 'react-mapbox-gl'
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions'
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css'
import mapboxgl from 'mapbox-gl'
// import FontPicker from 'font-picker-react'
import axios from 'axios'
import ProgressDialog from '../ProgressDialog/ProgressDialog'
import FontPicker from 'font-picker-react'
import createUUID from '../createUUID'
import EditingTabs from '../EditingTabs/EditingTabs'
import mapStyles from '../mapStyles'
import fontFamilies from '../fontFamilies'

const PRODUCT_DATA = {
  SIZES: ['24" x 36"', '20" x 30"', '12" x 18"'],
  TYPES: ['Premium Matte Poster', 'Download Only']
}

//todo fetch this with buy sdk
const BUY_VARIANTS = [
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDAxNzMyMA==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[0],
    price: '$10'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDA4Mjg1Ng==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[1],
    price: '$10'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDE0ODM5Mg==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[2],
    price: '$10'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM3OTk4NDU1Mg==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[0],
    price: '$30'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDA1MDA4OA==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[1],
    price: '$24'
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDExNTYyNA==',
    type: PRODUCT_DATA.TYPES[0],
    size: PRODUCT_DATA.SIZES[2],
    price: '$17'
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  textField: { },
  grid: {
    // padding: theme.spacing(2)
  },
  stepper: {
    // margin: theme.spacing(2)
    backgroundColor: '#dadde3'
  },
  media: {
    height: 140
  },
  card: {
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    // Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    fontFamily: ['Helvetica', '"Helvetica Neue"', 'Arial', '"Lucinda Grande"', 'sans-serif'],
    letterSpacing: '0.1em',
    fontSize: '1.33333em',
    fontWeight: '700',
    color: '#3a3a3a'
  },
  appBar: {
    backgroundColor: '#fff'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  mainLetter: {
    fontFamily: "Alfa Slab One"
  }
}))

const ACCESS_TOKEN = 'pk.eyJ1IjoiamF5dG9uYmlyY2giLCJhIjoiY2tiamxnZXQzMG82OTJ6azA2c3VoODlxZSJ9.yf1iANJU2yxYLlLvzwpRUg'
const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiamF5dG9uYmlyY2giLCJhIjoiY2tiamxnZXQzMG82OTJ6azA2c3VoODlxZSJ9.yf1iANJU2yxYLlLvzwpRUg',
  preserveDrawingBuffer: true
})
mapboxgl.accessToken = 'pk.eyJ1IjoiamF5dG9uYmlyY2giLCJhIjoiY2tiamxnZXQzMG82OTJ6azA2c3VoODlxZSJ9.yf1iANJU2yxYLlLvzwpRUg'

const defaultData = {
  buyVariant: {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIzOTM4MDAxNzMyMA==',
    type: PRODUCT_DATA.TYPES[1],
    size: PRODUCT_DATA.SIZES[0],
    price: '$10'
  },
  startingLocation: {},
  finalLocation: {},
  startingLocationFormValue: null,
  finalLocationFormValue: null,
  title: 'Eagle Mountain',
  subtitle: 'UT, USA',
  mapStyle: mapStyles[1].subStyles[4],
  styleCategory: mapStyles[1],
  fontFamily: fontFamilies[37].name,
  secondaryFontFamily: fontFamilies[43].name,
  center: {lng: -111.978302, lat: 40.361614},
  routeResponse: null,
  showRouteSelect: false

}

function RegMapPosterBuilder ({addItemsToCart}) {
  const [itemData, setItemData] = React.useState(defaultData)
  const [fetching, setFetching] = React.useState(false)
  const [posterDimentions, setPosterDimentions] = React.useState()
  const classes = useStyles()
  const posterRef = React.useRef(null)
  const mapRef = React.useRef(null)
  const printRef = React.useRef(null)
  const [bounds, setBounds] = React.useState()
  const [goingBounds, setGoingBounds] = React.useState()
  const [map, setMap] = React.useState()

  React.useEffect(() => {
    if (itemData.routeResponse) {
      const coordinates = itemData.routeResponse.routes[0].geometry.coordinates
      const bounds = coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      setBounds(bounds)
    }
  }, [itemData.routeResponse])

  React.useEffect(() => {
    if(map) {
      map.dragRotate.disable()
      map.touchZoomRotate.disableRotation()
    }
  }, [map])
  React.useEffect(() => {
    if (map && bounds) {
    map.fitBounds(bounds, {padding: {top: 30, left: 30, right: 30, bottom: 220}})
    }
  }, [bounds, map])

  React.useEffect(() => {
    if (posterRef?.current) {
      console.log('dimentions',posterRef.current.getBoundingClientRect())
      setPosterDimentions(posterRef.current.getBoundingClientRect())
    }
  }, [posterRef, setPosterDimentions])

  return (
    <div
      css={css`background-color: #fff; height: 100%; min-height: 100vh; display: flex; flex-direction
: column`} className={classes.root}
    >
      {fetching && <ProgressDialog loading={fetching} setLoading={setFetching} fetchResolved />}
      <div css={css`flex-grow: 1; padding-top: 20px`}>
        <Grid className={classes.grid} justify='center' direction='row-reverse' container spacing={2} style={{marginBottom: '100px'}}>
          <Grid item lg={6} xs={12} style={{ marginBottom: '20px', width: '100%', height: ((posterDimentions?.width * 1620) / 1080) + 50 }}>
            <div ref={posterRef}  style={{ width: '80%', height: posterDimentions?.height, marginBottom: '25px', marginRight: 'auto', marginLeft: 'auto' }}>
              {posterDimentions?.width && (
                      <Paper
                        ref={mapRef}
                        elevation={6}
                        id='map-display'
                        style={{
                          width: '1080px',
                          height: '1620px',
                          transform: `scale(${posterDimentions.width / 1080})`,
                          transformOrigin: 'left top',
                          borderStyle: 'solid',
                          borderColor: 'white',
                          borderWidth: '30px'
                        }}>
                        <div ref={printRef} id="to-print" style={{position: 'relative'}}>
                        <div
                          style={{
                            width: '101%',
                            height: '200px',
                            position: 'absolute',
                            bottom: -1,
                            left: -1,
                            zIndex: 5,
                            backgroundColor: 'white'
                          }}>
                          <div style={{fontSize: '90px', paddingLeft: '20px', paddingRight: '20px', fontFamily: itemData.fontFamily}}>{itemData.title}</div>
                          <div style={{fontSize: '30px', paddingLeft: '20px', paddingRight: '20px', fontFamily: itemData.secondaryFontFamily, fontWeight: 400}}>{itemData.subtitle}</div>
                        </div>
                        <Map
                          center={[itemData.center.lng, itemData.center.lat]}
                          onMoveEnd={(map, event) => {
                            setItemData(prev => ({...prev, center: map.getCenter()}))
                            setGoingBounds(map.getBounds())
                          }}
                          // eslint-disable-next-line react/style-prop-object
                          style={itemData.mapStyle.url}
                          containerStyle={{
                            height: '1620px',
                            width: '1080px'
                          }}
                        >
                          {itemData.routeResponse && (
                         <GeoJSONLayer data={{type: "Feature", properties: {}, geometry: {type: 'LineString', coordinates: itemData.routeResponse.routes[0].geometry.coordinates}}} lineLayout={{'line-join': 'round',
                           'line-cap': 'round'}} linePaint={{'line-color': '#ffe866',
                           'line-width': 6}}/>
                          )}
                          <MapContext.Consumer>
                            {(newMap) =>{
                              setMap(newMap)
                            }}
                          </MapContext.Consumer>
                        </Map>
                        </div>
                        <div style={{marginTop: '50px', fontSize: '28px', color: 'grey'}}>
                        © <a style={{color: 'grey'}} href='https://www.mapbox.com/about/maps/'>Mapbox</a> | © <a style={{color: 'grey'}} href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> | <a style={{color: 'grey'}} href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a>
                        </div>
                      </Paper>
              )}
            </div>

          </Grid>
          <Grid item lg={4} xs={11}>
            <EditingTabs itemData={itemData} setItemData={setItemData}/>
            <Button
              onClick={() => {
                // client.product.fetchAll().then(products => console.log('products', products))
                const lineItemsToAdd = [
                  {
                    variantId: itemData.buyVariant.id,
                    quantity: 1,
                    customAttributes: [
                      {key: '_image_id', value: createUUID()},
                      {key: 'startingLocation', value: JSON.stringify(itemData.startingLocation)},
                      {key: 'finalLocation', value: JSON.stringify(itemData.finalLocation)},
                      {key: 'title', value: itemData.title},
                      {key: 'subtitle', value: itemData.subtitle},
                      {key: 'mapStyle', value: itemData.mapStyle.url},
                      {key: 'fontFamily', value: itemData.fontFamily},
                      {key: 'secondaryFontFamily', value: itemData.secondaryFontFamily},
                      {key: '_bounds_sw', value: JSON.stringify(goingBounds['_sw'])},
                      {key: '_bounds_ne', value: JSON.stringify(goingBounds['_ne'])},
                    ]
                  }
                ]
                addItemsToCart(lineItemsToAdd)
              }}
              variant={"contained"}
              color={"secondary"}
              size={"large"}
              fullWidth>
              <Typography style={{fontFamily: 'Bungee Inline'}} component={'h5'} variant={'h5'}>
                <span>{`${itemData.buyVariant.price} - `}</span>
                Add to Cart
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default RegMapPosterBuilder
