const fontFamilies = [
  { name: 'Abhaya Libre' },
  { name: 'Adamina' },
  { name: 'Alegreya' },
  { name: 'Alegreya Sans SC' },
  { name: 'Annie Use Your Telescope' },
  { name: 'Architects Daughter' },
  { name: 'Arsenal' },
  { name: 'Bad Script' },
  { name: 'Bellota' },
  { name: 'Bevan' },
  { name: 'Bitter' },
  { name: 'Cardo' },
  { name: 'Caveat' },
  { name: 'Charmonman' },
  { name: 'Cinzel' },
  { name: 'Cutive Mono' },
  { name: 'Dawning of a New Day' },
  { name: 'Didact Gothic' },
  { name: 'Flamenco' },
  { name: 'Frank Ruhl Libre' },
  { name: 'Fredoka One' },
  { name: 'Fresca' },
  { name: 'Gentium Basic' },
  { name: 'Handlee' },
  { name: 'Ibarra Real Nova' },
  { name: 'IM Fell Double Pica' },
  { name: 'Indie Flower' },
  { name: 'Kirang Haerang' },
  { name: 'Londrina Solid' },
  { name: 'Marcellus SC' },
  { name: 'Merriweather' },
  { name: 'Montserrat' },
  { name: 'Oswald' },
  { name: 'Playfair Display' },
  { name: 'Pompiere' },
  { name: 'Raleway' },
  { name: 'Sarina' },
  { name: 'Staatliches' },
  { name: 'Stoke' },
  { name: 'Tenor Sans' },
  { name: 'Ultra' },
  { name: 'Wire One' },
  { name: 'Yeseva One' },
  { name: 'Open Sans' },
  { name: 'Montserrat' },
  { name: 'Lato' },
  { name: 'Raleway' },
  { name: 'Source Sans Pro' }
]

export default fontFamilies
