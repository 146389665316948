import React from 'react'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
  stepper: {
    backgroundColor: '#dadde3'
  }
}))
function MainStepper ({ activeStep }) {
  const classes = useStyles()
  return (
    <Stepper className={classes.stepper} activeStep={activeStep}>
      <Step>
        <StepLabel key='first one'>
           Design
        </StepLabel>
      </Step>
      <Step>
        <StepLabel key='second'>
           Add TinyText
        </StepLabel>
      </Step>
      <Step>
        <StepLabel key='third'>
           Download
        </StepLabel>
      </Step>
    </Stepper>)
}
export default MainStepper
